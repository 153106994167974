<template>
  <Support />
</template>

<script>
import Support from '@/components/Support.vue'

export default {
  components: {
    Support,
  },
}
</script>
